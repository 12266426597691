import axios from 'axios'
import auth from './authentication'

let baseUrl = "https://prod.aawaz.com"

const getSearch = () => {
    return axios({
        method: "GET",
        url: `${baseUrl}/api/searchcontent-v2/`
    })
}

const postSearch = (data) => {
    return axios({
        method: "POST",
        headers: { 'Content-Type': "application/json" },
        url: `${baseUrl}/search/`,
        data: JSON.stringify(data)
    })
}

const getAllSections = (token) => {
    return axios({
        method: "GET",
        url: `${baseUrl}/web/dashboard-v2/?limit=100`,
        headers: {
            Authorization: `Bearer ${token}`,
        }
    })
}

const getAllBanners = () => {
    return axios({
        method: "GET",
        // url:`${baseUrl}/web/banner-details/`
        url: `${baseUrl}/web/banners-v2/`,
        auth: auth
    })
}

const getShowByURL = (shortUrl, token) => {
    return axios({
        method: "GET",
        url: `${baseUrl}/web/show-details/?short_url=${shortUrl}`,
        headers: {
            Authorization: `Bearer ${token}`,
        }
    })
}
const getRelatedShows = (show_slug, token) => {
    return axios({
        method: "GET",
        url: `${baseUrl}/web/show-details/${show_slug}/related_shows/`,
        headers: {
            Authorization: `Bearer ${token}`,
        }
    })
}
const getCategoryByURL = (shortUrl) => {
    return axios({
        method: "GET",
        url: `${baseUrl}/web/category-details-v2/${shortUrl}/`,
    })
}

const getEpisodeBySlug = (episodeSlug, token) => {
    // /web/episode - details /
    return axios({
        method: "GET",
        url: `${baseUrl}/web/episode-details/${episodeSlug}/`,
        headers: {
            Authorization: `Bearer ${token}`,
        }
    })
}

const getAllCategories = (token) => {
    return axios({
        method: "GET",
        url: `${baseUrl}/api/category/?all=True`,
        headers: {
            Authorization: `Bearer ${token}`,
        }
    })
}


const getAllChannel = (token) => {
    return axios({
        method: "GET",
        url: `${baseUrl}/api/channel/`,
        headers: {
            Authorization: `Bearer ${token}`,
        }
    })
}


const getChannelByURL = (shortUrl, token) => {
    return axios({
        method: "GET",
        url: `${baseUrl}/web/channel-details/?short_url=${shortUrl}`,
        headers: {
            Authorization: `Bearer ${token}`,
        }
    })
}

const getComments = (id) => {
    return axios({
        method: "GET",
        url: `${baseUrl}/api/comments/?show_slug=${id}`
    })
}

const getEpisodeByURL = (shortUrl, token) => {
    return axios({
        method: "GET",
        url: `${baseUrl}/web/episode-details/?short_url=${shortUrl}`,
        headers: {
            Authorization: `Bearer ${token}`,
        }
    })
}

const createAnonUser = (data) => {
    return axios({
        method: "POST",
        headers: { 'Content-Type': "application/json" },
        url: `${baseUrl}/api/anonymous/`,
        // auth: auth,
        data: {
            "firebase_user_uuid": data
        }
    })
}

const getCurrLoggedInUser = (anonId, currUserId, token) => {
    return axios({
        method: "GET",
        url: `${baseUrl}/api/getuser/`,
        headers: {
            Authorization: `Bearer ${token}`,
            isAnonymous: "True",
            LastFirebaseUUID: anonId,
            CurrentFirebaseUUID: currUserId
        }
    })
}

const updateUserDetails = (data, id, token) => {
    return axios({
        method: "PATCH",
        url: `${baseUrl}/api/users/${id}/`,
        headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json'
        },
        data: data
    })
}

const likeEpisode = (token, data) => {
    return axios({
        method: "POST",
        url: `${baseUrl}/api/userlikes/`,
        headers: {
            Authorization: `Bearer ${token}`
        },
        data: {
            "episode_slug": data
        }
    })
}

const dislikeEpisode = (token, data) => {
    return axios({
        method: "POST",
        url: `${baseUrl}/api/userlikes/`,
        headers: {
            Authorization: `Bearer ${token}`
        },
        data: {
            "episode_slug": data,
            "dislike": true
        }
    })
}

const subscribe = (token, data, type) => {
    return axios({
        method: "POST",
        url: `${baseUrl}/api/subscribe${type}/`,
        headers: {
            Authorization: `Bearer ${token}`
        },
        data: type === "channel" ? { "channel_slug": data } : { "show_slug": data }
    })
}

const unsubscribe = (token, data, type) => {
    return axios({
        method: "POST",
        url: `${baseUrl}/api/subscribe${type}/`,
        headers: {
            Authorization: `Bearer ${token}`
        },
        data: type === "channel" ? { "channel_slug": data, "unsubscribe": true } : { "show_slug": data, "unsubscribe": true }
    })
}

const postShowComment = (token, showSlug, msg) => {
    return axios({
        method: "POST",
        url: `${baseUrl}/api/comments/`,
        headers: {
            Authorization: `Bearer ${token}`
        },
        data: {
            "show_slug": showSlug,
            "message": msg
        }
    })
}

const postEpisodeComment = (token, showSlug, epiSlug, msg) => {
    return axios({
        method: "POST",
        url: `${baseUrl}/api/comments/`,
        headers: {
            Authorization: `Bearer ${token}`
        },
        data: {
            "show_slug": showSlug,
            "episode_slug": epiSlug,
            "message": msg
        }
    })
}

const getUserSubsciptions = (token) => {
    return axios({
        method: "GET",
        url: `${baseUrl}/api/subscriptions/`,
        headers: {
            Authorization: `Bearer ${token}`,
        }
    })
}

const updateLanguage = (token, data, userId) => {
    return axios({
        method: "PATCH",
        url: `${baseUrl}/api/users/${userId}/`,
        headers: {
            Authorization: `Bearer ${token}`
        },
        data: {
            "app_language_names": data,
        }
    })
}

const getLanguages = (token) => {
    return axios({
        method: "GET",
        url: `${baseUrl}/api/app-language/`,
        headers: {
            Authorization: `Bearer ${token}`,
        }
    })
}
const getContinueListening = (userId, token) => {
    return axios({
        method: "GET",
        url: `${baseUrl}/api/users/${userId}/continue_listening/`,
        headers: {
            Authorization: `Bearer ${token}`,
        }
    })
}
const postRecentSearch = (userId, token, showId) => {
    return axios({
        method: "POST",
        url: `${baseUrl}/api/recent-search/`,
        headers: {
            Authorization: `Bearer ${token}`
        },
        data: {
            "show": showId,
            "user": userId
        },
    })

}
const getRecentSearch = (token) => {
    return axios({
        method: "GET",
        url: `${baseUrl}/api/recent-search/`,
        headers: {
            Authorization: `Bearer ${token}`,
        }
    })
}
const postContinueListening = (userId, token, showSlug, epiSlug, playDuration) => {
    return axios({
        method: "POST",
        url: `${baseUrl}/api/users/${userId}/continue_listening/`,
        headers: {
            Authorization: `Bearer ${token}`
        },
        data: {
            "show_slug": showSlug,
            "episode_slug": epiSlug,
            "playhead_position": playDuration

        }
    })
}
export {
    getAllSections, getAllBanners, getAllCategories, getAllChannel, getSearch, postSearch, getComments, getShowByURL, getCategoryByURL,
    getChannelByURL, getEpisodeByURL, createAnonUser, getCurrLoggedInUser, updateUserDetails,
    likeEpisode, dislikeEpisode, subscribe, unsubscribe, postEpisodeComment, postShowComment, getUserSubsciptions, updateLanguage,
    getLanguages, getRelatedShows, getEpisodeBySlug, getContinueListening, postContinueListening, postRecentSearch, getRecentSearch
}